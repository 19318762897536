import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button, Card, Grid, IconButton, TextField } from '@mui/material';
import { useGlobalStore } from '../utilities/GlobalState';
import { GlobalState, Module } from '../interfaces/Interfaces';
import { ModulePreviewCard } from '../modules/ModulePreviewCard';
import { AuthPost } from '../utilities/Routing/Requests';
import { toast } from 'react-toastify';
import { Datagrid } from '../utilities/Datagrid';
import { DateTime } from 'luxon';
import { formatDateWithTime2 } from '../utilities/Utilites';
import AddIcon from '@mui/icons-material/Add';
import SliderSwitch from '../utilities/SliderSwitch';
import { ModulePreviewDetails } from './ModulePreviewDetails';

export interface Props {
    open: boolean;
    modules: Array<Module>;
    classID: number;
    handleClose: (refresh?: boolean) => void;
}

export const AddModuleModal = ({
    open,
    handleClose,
    modules,
    classID,
}: Props) => {
    const globalState = useGlobalStore((state: GlobalState) => state);
    const [selectedModule, setSelectedModule] = React.useState<
        number | undefined
    >();
    const [localModules, setLocalModules] =
        React.useState<Array<Module>>(modules);
    const [showModuleDetails, setShowModuleDetails] = React.useState<Module>();
    const [cardView, setCardView] = React.useState<boolean>(true);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        AuthPost(
            '/classdetail/addclassdetail',
            {
                moduleID: selectedModule,
                classID: classID,
            },
            {
                'x-access-token': globalState.authToken,
            },
        )
            .then((res) => {
                if (res?.status === 200) {
                    toast.success('Class Successfully Updated!');
                    handleClose(true);
                }
            })
            .catch((err) => {
                toast.error(err.toString());
            });
    };

    const onInputChange = async (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        const searchTerm = e.target.value;
        const newModules = modules.filter((m: Module) => {
            return m.title.includes(searchTerm);
        });
        await setLocalModules(newModules);
    };

    const toggleSelectedModule = (id: number) => {
        setSelectedModule(id !== selectedModule ? id : undefined);
    };
    return (
        <Modal
            open={open}
            onClose={() => handleClose()}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                outline: 'none',
            }}
        >
            <Card
                sx={{
                    width: '800px',
                    margin: '10px',
                    outline: 'none',
                    overflow: 'auto',
                }}
            >
                {showModuleDetails ? (
                    <>
                        <ModulePreviewDetails module={showModuleDetails} />
                    </>
                ) : (
                    <Box
                        sx={{
                            margin: '20px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Grid
                            container
                            sx={{ justifyContent: 'space-between' }}
                        >
                            <Grid
                                xs={8}
                                sx={{
                                    textAlignLast: 'right',
                                }}
                            >
                                <Typography component="h1" variant="h5">
                                    Available Modules
                                </Typography>
                            </Grid>
                            <Grid
                                xs={4}
                                sx={{
                                    textAlignLast: 'right',
                                }}
                            >
                                Card
                                <SliderSwitch
                                    sx={{ m: 1 }}
                                    value={cardView}
                                    onChange={() => setCardView(!cardView)}
                                />
                                Table
                            </Grid>
                        </Grid>
                        <Box
                            component="form"
                            onSubmit={handleSubmit}
                            sx={{ mt: 1 }}
                        >
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                ADD MODULE
                            </Button>
                        </Box>
                        <div
                            style={{
                                width: '100%',
                                margin: '20px',
                            }}
                        >
                            <TextField
                                // fullWidth
                                name="search"
                                label="Module Title Filter"
                                type="text"
                                sx={{
                                    width: '100%',
                                    // padding: '20px',
                                }}
                                onChange={onInputChange}
                                // InputProps={{
                                //     endAdornment: removeInput(i),
                                // }}
                            />
                        </div>
                        <Grid
                            container
                            sx={{ justifyContent: 'space-between' }}
                        >
                            {cardView
                                ? localModules.map((module) => {
                                      return (
                                          <div
                                              key={module.id}
                                              onClick={() =>
                                                  toggleSelectedModule(
                                                      module.id,
                                                  )
                                              }
                                              onDoubleClick={() =>
                                                  setShowModuleDetails(module)
                                              }
                                              className={
                                                  selectedModule === module.id
                                                      ? 'Border-Selected-500'
                                                      : ''
                                              }
                                              style={{
                                                  display: 'flex',
                                                  flexDirection: 'column',
                                                  alignItems: 'center',
                                                  marginBottom: '20px',
                                              }}
                                          >
                                              <ModulePreviewCard
                                                  key={module.id}
                                                  handleToggleModulePage={() => {
                                                      return;
                                                  }}
                                                  module={module}
                                              />
                                          </div>
                                      );
                                  })
                                : Datagrid({
                                      sx: { height: 'unset' },
                                      editMode: 'row',
                                      onRowClick: (params: any) =>
                                          setSelectedModule(params.row.id),
                                      columns: [
                                          {
                                              field: 'id',
                                              headerName: 'ID',
                                              type: 'string',
                                              flex: 0.25,
                                          },
                                          {
                                              field: 'title',
                                              headerName: 'Title',
                                              type: 'string',
                                              flex: 1,
                                          },
                                          {
                                              field: 'description',
                                              headerName: 'Description',
                                              type: 'string',
                                              flex: 1,
                                          },
                                          {
                                              field: 'publishDate',
                                              headerName: 'Publish Date',
                                              type: 'string',
                                              flex: 1,
                                              renderCell: (rowData) => {
                                                  const date = DateTime.fromISO(
                                                      rowData.row
                                                          .publishDate as any,
                                                  ) as any;
                                                  return rowData?.row
                                                      ?.publishDate
                                                      ? formatDateWithTime2(
                                                            date,
                                                        )
                                                      : 'Unpublished';
                                              },
                                          },
                                          {
                                              field: 'action',
                                              headerName: 'Action',
                                              flex: 1,
                                              sortable: false,
                                              renderCell: (rowData) => {
                                                  return (
                                                      <>
                                                          <IconButton
                                                              sx={{
                                                                  marginLeft:
                                                                      '5px',
                                                                  backgroundColor:
                                                                      '#f1c40f',
                                                                  border: 'solid #fff 1px',
                                                                  color: '#fff',
                                                                  fontWeight:
                                                                      'bold',
                                                                  fontSize:
                                                                      '20px',
                                                              }}
                                                              title="Add"
                                                              onClick={() => {
                                                                  return AuthPost(
                                                                      '/classdetail/addclassdetail',
                                                                      {
                                                                          moduleID:
                                                                              rowData
                                                                                  ?.row
                                                                                  ?.id,
                                                                          classID:
                                                                              classID,
                                                                      },
                                                                      {
                                                                          'x-access-token':
                                                                              globalState.authToken,
                                                                      },
                                                                  )
                                                                      .then(
                                                                          (
                                                                              res,
                                                                          ) => {
                                                                              if (
                                                                                  res?.status ===
                                                                                  200
                                                                              ) {
                                                                                  toast.success(
                                                                                      'Class Successfully Updated!',
                                                                                  );
                                                                                  handleClose(
                                                                                      true,
                                                                                  );
                                                                              }
                                                                          },
                                                                      )
                                                                      .catch(
                                                                          (
                                                                              err,
                                                                          ) => {
                                                                              toast.error(
                                                                                  err.toString(),
                                                                              );
                                                                          },
                                                                      );
                                                              }}
                                                          >
                                                              <AddIcon />
                                                          </IconButton>
                                                      </>
                                                  );
                                              },
                                          },
                                      ],
                                      data: modules,
                                  })}
                        </Grid>
                        <Box
                            component="form"
                            onSubmit={handleSubmit}
                            sx={{ mt: 1 }}
                        >
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                ADD MODULE
                            </Button>
                        </Box>
                    </Box>
                )}
            </Card>
        </Modal>
    );
};
