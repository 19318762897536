import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { defaultTheme } from '../utilities/Utilites';
import Box from '@mui/system/Box';
import { Button, Card, TextField } from '@mui/material';
import packageJson from '../../package.json';
import '../styles/UserAgentInfo.css';
import { getUserAgentDetails } from '../utilities/Utilites';
import { useGlobalStore } from '../utilities/GlobalState';
import { toast } from 'react-toastify';
import { AuthPost } from '../utilities/Routing/Requests';

export const BugReport = () => {
    const [details, setDetails] = useState<any>({});
    useEffect(() => {
        const userDetails: any = getUserAgentDetails();
        userDetails.version = packageJson.version;
        setDetails(userDetails);
    }, []);
    const globalState = useGlobalStore((state) => state);
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const body = {
            device: details,
            title: data.get('title'),
            expected: data.get('expected'),
            actual: data.get('actual'),
            email: data.get('email'),
        };
        AuthPost('/user/report', body, {
            'x-access-token': globalState.authToken,
        })
            .then((res) => {
                if (res?.status === 200) {
                    toast.success('Issue Report Sent!');
                }
            })
            .catch((err) => {
                toast.error(err.toString());
            });
    };
    return (
        <ThemeProvider theme={defaultTheme}>
            <Card
                sx={{
                    maxWidth: '100%',
                    padding: '20px',
                    textAlign: 'center',
                }}
            >
                <Box
                    sx={{
                        padding: '30px 10px 10px 10px',
                        margin: '10px 10px 10px 10px',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                    component="form"
                    onSubmit={handleSubmit}
                >
                    <h3>Report an Issue</h3>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="title"
                        label="Title"
                        name="title"
                        autoFocus
                        sx={{
                            marginBottom: '20px',
                        }}
                    />
                    <TextField
                        fullWidth
                        sx={{
                            marginBottom: '20px',
                        }}
                        label="Expected Behavior"
                        multiline={true}
                        minRows={3}
                        required
                        id="expected"
                        name="expected"
                        aria-label="Expected Behavior"
                        placeholder="Expected Behavior *"
                    />
                    <TextField
                        sx={{
                            marginBottom: '20px',
                        }}
                        fullWidth
                        label="Actual Behavior"
                        multiline={true}
                        minRows={3}
                        required
                        id="actual"
                        name="actual"
                        aria-label="Actual Behavior"
                        placeholder="Actual Behavior *"
                    />
                    <TextField
                        sx={{
                            marginBottom: '20px',
                        }}
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mb: 2 }}
                    >
                        SUBMIT
                    </Button>
                </Box>
            </Card>
        </ThemeProvider>
    );
};
