import axios, {
    AxiosInstance,
    AxiosResponse,
    InternalAxiosRequestConfig,
    RawAxiosRequestHeaders,
} from 'axios';
import customHistory from './History';
interface CustomError extends Error {
    isSkipXHR: boolean;
    requestConfig: CustomConfig;
}

interface CustomConfig extends InternalAxiosRequestConfig {
    isFinished?: boolean;
}

interface Caches {
    [key: string]: AxiosResponse;
}

interface Pending {
    [key: string]: Array<CustomConfig>;
}

interface CustomHeaders extends RawAxiosRequestHeaders {
    canCache?: boolean;
    'x-access-token'?: string;
    employeeUsername?: string;
    Customer_id?: string;
    showClosed?: boolean;
    customer?: string;
}

interface CacheUtils {
    getUniqueUrl: (config: CustomConfig) => string;
    isCached: (config: CustomConfig) => boolean;
    isPending: (config: CustomConfig) => boolean;
    setCachedResponse: (config: CustomConfig, response: AxiosResponse) => void;
    getError: (config: CustomConfig) => CustomError;
    getCachedResponse: (config: CustomConfig) => AxiosResponse;
}

// // If the same url is hit repeatedly within 1000ms response will be cached and served to all requests. This will prevent multiple api calls to the same endpoint caused by re-renders.
const pendings: Pending = {};
const caches: Caches = {};
const cacheUtils: CacheUtils = {
    getUniqueUrl: function (config: CustomConfig) {
        return config.url + '&' + config.method;
    },
    isCached: function (config: CustomConfig) {
        const uniqueUrl = this.getUniqueUrl(config);
        return caches[uniqueUrl] !== undefined;
    },
    isPending: function (config: CustomConfig) {
        const uniqueUrl = this.getUniqueUrl(config);
        if (!pendings[uniqueUrl]) {
            pendings[uniqueUrl] = [config];
            return false;
        } else {
            pendings[uniqueUrl].push(config);
            return true;
        }
    },
    setCachedResponse: function (
        config: CustomConfig,
        response: AxiosResponse,
    ) {
        const uniqueUrl = this.getUniqueUrl(config);
        caches[uniqueUrl] = response;
        if (pendings[uniqueUrl]) {
            pendings[uniqueUrl].forEach((configItem: CustomConfig) => {
                configItem.isFinished = true;
            });
        }
    },
    getError: function (config: CustomConfig) {
        const skipXHRError: CustomError = new Error('skip') as CustomError;
        skipXHRError.isSkipXHR = true;
        skipXHRError.requestConfig = config;
        return skipXHRError;
    },
    getCachedResponse: function (config: CustomConfig) {
        const uniqueUrl = this.getUniqueUrl(config);
        return caches[uniqueUrl];
    },
};

const AuthInstance = (additionalHeaders?: CustomHeaders): AxiosInstance => {
    const newAxios = axios.create({
        // baseURL: 'https://api.industrytrainingacademy.com',
        baseURL: process.env.REACT_APP_API_URL || 'http://localhost:4001',
        // baseURL: 'http://ita-qa-api.eastus.azurecontainer.io:4001',

        headers: {
            ...additionalHeaders,
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
        },
    });

    newAxios.interceptors.request.use(function (config: CustomConfig) {
        // to avoid careless bug, only the request that explicitly declares *canCache* parameter can use cache
        if (config.canCache) {
            if (cacheUtils.isCached(config)) {
                const error = cacheUtils.getError(config);
                throw error;
            }
            if (cacheUtils.isPending(config)) {
                return new Promise((resolve, reject) => {
                    const interval = setInterval(() => {
                        if (config.isFinished) {
                            clearInterval(interval);
                            const error = cacheUtils.getError(config);
                            reject(error);
                        }
                    }, 200);
                });
            } else {
                // the head of cacheable requests queue, get the response by http request
                return config;
            }
        } else {
            return config;
        }
    });

    newAxios.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error?.response?.status === 401) {
                customHistory.replace('/login');
            }
        },
    );
    return newAxios;
};

// TODO remove ANY
// eslint-disable-next-line
const AuthPost = (url: string, body: any, additionalHeaders?: CustomHeaders) =>
    AuthInstance(additionalHeaders).post(url, body);

const AuthPut = (url: string, body: any, additionalHeaders?: CustomHeaders) =>
    AuthInstance(additionalHeaders).put(url, body);

const AuthDelete = (url: string, additionalHeaders?: CustomHeaders) =>
    AuthInstance(additionalHeaders).delete(url);

const AuthGet = (url: string, additionalHeaders?: CustomHeaders) =>
    AuthInstance(additionalHeaders).get(url);

export { AuthGet, AuthPost, AuthPut, AuthDelete };
