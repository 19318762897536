import { useLayoutEffect, useState } from 'react';
import { Routes } from 'react-router-dom';
import * as React from 'react';

const CustomRouter = ({ history, ...props }: any) => {
    const [state, setState] = useState({
        action: history.action,
        location: history.location,
    });

    useLayoutEffect(() => history.listen(setState), [history]);

    return (
        <Routes
            {...props}
            location={state.location}
            navigationType={state.action}
            navigator={history}
        />
    );
};

export default CustomRouter;
