('use client');
import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import './styles/index.css';
import { App } from './App';
import { BrowserRouter } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const container = document.getElementById('root') as Element;
const root = ReactDOMClient.createRoot(container);

root.render(
    <React.StrictMode>
        <BrowserRouter>
            {/* <Auth0ProviderWithNavigate> */}
            <App />
            {/* </Auth0ProviderWithNavigate> */}
        </BrowserRouter>
    </React.StrictMode>,
);
