import * as React from 'react';
import Card from '@mui/material/Card';
import { GlobalState, Module } from '../interfaces/Interfaces';
import GradingIcon from '@mui/icons-material/Grading';
import { IconButton } from '@mui/material';
import { useGlobalStore } from '../utilities/GlobalState';

interface Props {
    handleToggleModulePage: () => void;
    module: Module;
    showGradebook?: () => void;
}

const ModulePreviewCard = ({
    handleToggleModulePage,
    module,
    showGradebook,
}: Props) => {
    const globalState = useGlobalStore((state: GlobalState) => state);
    const backImg = module?.image?.length ? module.image : '';
    const handleClick = (e: any) => {
        if (
            (e.target.getAttribute('data-testid') === 'GradingIcon' ||
                e?.target?.id === 'button') &&
            showGradebook
        ) {
            showGradebook();
        } else {
            handleToggleModulePage();
        }
    };
    return (
        <Card
            onClick={handleClick}
            style={{
                backgroundImage: 'url(' + backImg + ')',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'bottom',
                boxShadow:
                    '0px 2px 11px -1px rgba(0,0,0,4.8),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
            }}
            sx={{
                margin: '10px',
                width: '200px',
                height: '300px',
                display: 'flex',
                flexDirection: 'column',
                // backgroundImage: backImg as string,
            }}
            id="parent"
        >
            {showGradebook && globalState.user?.type.includes('Student') && (
                <IconButton
                    id="button"
                    sx={{
                        // position: 'fixed',
                        // marginLeft: '5px',
                        backgroundColor: '#f1c40f',
                        border: 'solid #f1c40f 1px',
                        color: '#fff',
                        fontWeight: 'bold',
                        fontSize: '20px',
                        borderRadius: 'unset',
                    }}
                    title={'Gradebook'}
                >
                    <GradingIcon />
                    Gradebook
                </IconButton>
            )}
            <div
                style={{
                    margin: '10px 10px 10px 10px',
                    fontWeight: 'bolder',
                    textAlign: 'center',
                }}
            >
                {module?.code
                    ? module?.code + ': ' + module?.title
                    : module?.title}
            </div>
            <div
                style={{
                    margin: '10px 10px 10px 10px',
                    height: '50px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textAlign: 'center',
                }}
                title={module?.description}
            >
                {module?.description}
            </div>
            <div
                style={{
                    backgroundImage: backImg as string,
                }}
            />
        </Card>
    );
};

export { ModulePreviewCard };
