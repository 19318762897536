import * as React from 'react';
import {
    DataGridPro,
    GridColDef,
    GridEditMode,
    GridRowParams,
    GridValidRowModel,
} from '@mui/x-data-grid-pro';
import { SxProps, Theme } from '@mui/material';

// interface DataGridProComponent {
//     <R extends GridValidRowModel = any>(
//         props: DataGridProProps<R> & React.RefAttributes<HTMLDivElement>,
//     ): React.JSX.Element;
//     propTypes?: any;
// }

export interface Props extends GridValidRowModel {
    columns: GridColDef[];
    data: any[];
    multiselect?: boolean;
    disableRowSelectionOnClick?: boolean;
    sx?: SxProps<Theme>;
    pageSize?: number;
    getRowId?: (row: any) => any;
    getDetailPanelContent?: (params: GridRowParams<any>) => React.ReactNode;
    getDetailPanelHeight?: (params: GridRowParams<any>) => number | 'auto';
    editMode?: GridEditMode;
}

// TODO: add header? pagesize prop? styles for mobile?

export const Datagrid = (props: Props) => {
    return (
        <DataGridPro
            getRowId={props?.getRowId}
            sx={props.sx}
            rows={props.data || []}
            initialState={{
                pagination: {
                    paginationModel: {
                        pageSize: props?.pageSize || 5,
                    },
                },
            }}
            pageSizeOptions={[props?.pageSize || 5]}
            checkboxSelection={props.multiselect || false}
            disableRowSelectionOnClick={
                props.disableRowSelectionOnClick || false
            }
            getDetailPanelContent={props.getDetailPanelContent || undefined}
            getDetailPanelHeight={props.getDetailPanelHeight || undefined}
            editMode={props.editMode}
            {...props}
        />
    );
};

// export interface Columns {
//     field: string;
//     headerName: string;
//     description: string;
//     sortable?: boolean;
//     width?: number;
//     valueGetter?: (params: GridValueGetterParams) => string;
// }

// const columns: GridColDef[] = [
//     { field: 'id', headerName: 'ID', width: 90 },
//     {
//         field: 'firstName',
//         headerName: 'First name',
//         width: 150,
//         editable: true,
//     },
//     {
//         field: 'lastName',
//         headerName: 'Last name',
//         width: 150,
//         editable: true,
//     },
//     {
//         field: 'age',
//         headerName: 'Age',
//         type: 'number',
//          width: 110,
//         editable: true,
//     },
//     {
//         field: 'fullName',
//         headerName: 'Full name',
//         description: 'This column has a value getter and is not sortable.',
//         sortable: false,
//         width: 160,
//         valueGetter: (params: GridValueGetterParams) =>
//             `${params.row.firstName || ''} ${params.row.lastName || ''}`,
//     },
// ];

// const rows = [
//     { id: 1, lastName: 'Snow', firstName: 'Jon', age: 14 },
//     { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 31 },
//     { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 31 },
//     { id: 4, lastName: 'Stark', firstName: 'Arya', age: 11 },
//     { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
//     { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
//     { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
//     { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
//     { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
// ];
