import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button, Card, CssBaseline, Grid, TextField } from '@mui/material';
import { AuthPost } from '../utilities/Routing/Requests';
import { toast } from 'react-toastify';
import { ApiOrganization, OrganizationUser } from '../interfaces/Interfaces';
import { MultiSelect } from '../utilities/MultiSelect';
import { userSelectOptionMapper } from '../utilities/Utilites';

export interface Props {
    open: boolean;
    handleClose: (refresh?: boolean) => void;
    organization: ApiOrganization;
    authToken: string;
    selectedInstructor: OrganizationUser | null;
    selectedStudent: OrganizationUser | null;
    addStudentToClass?: (studentId: string) => void;
    allUsers?: Array<OrganizationUser>;
    bulkAddStudentToClass?: (studentId: Array<string>) => void;
}

export const CreateEditStudentModal = ({
    open,
    handleClose,
    organization,
    authToken,
    selectedInstructor,
    selectedStudent,
    addStudentToClass,
    allUsers,
    bulkAddStudentToClass,
}: Props) => {
    const localOrg = organization;
    const [existingUsers, setExistingUsers] = React.useState(false);
    const [selectedUsers, setSelectedUsers] = React.useState<Array<any>>([]);
    const [selectedUsersRender, setSelectedUsersRender] = React.useState<
        Array<any>
    >([]);

    const handleSubmit = (event: any) => {
        event.preventDefault();
        let orgUserIds = '';
        if (localOrg?.users?.length) {
            localOrg?.users?.forEach((user) => {
                orgUserIds = orgUserIds + user?.id?.toString() + ',';
            });
        }
        const data = new FormData(event.currentTarget);
        const studentBody = {
            userEmail: data.get('userEmail'),
            password: data.get('password'),
            firstName: data.get('firstName'),
            lastName: data.get('lastName'),
            AddToOrg: localOrg?.id,
            CurrentOrgUsers: orgUserIds,
            AddToInstructor: selectedInstructor?.id,
            // AAA: selectedInstructor?.students || '',
        };
        AuthPost('/organization/createstudent', studentBody, {
            'x-access-token': authToken,
        })
            .then((userRes) => {
                if (
                    userRes?.status === 200 ||
                    userRes?.status === 201 ||
                    userRes?.status === 204
                ) {
                    if (addStudentToClass) {
                        addStudentToClass(userRes?.data?.id);
                    }
                    //TODO add student id and instructor id to instructor table
                    toast.success('Student Created Successfully!');
                    handleClose(true);
                }
            })
            .catch((err) => {
                toast.error(err.toString());
            });
        handleClose(true);
    };
    const bulkSaveExistingStudents = () => {
        if (bulkAddStudentToClass) {
            const userIDS = selectedUsers?.map((user) => user?.key);
            bulkAddStudentToClass(userIDS);
        }
        //TODO add student id and instructor id to instructor table
        toast.success('Student Created Successfully!');
        handleClose(true);
    };

    return (
        <Modal
            open={open}
            onClose={() => handleClose()}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Card sx={{ width: '800px', margin: '10px', overflow: 'auto' }}>
                <CssBaseline />
                <Box
                    sx={{
                        margin: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Grid container>
                        <Grid
                            xs={4}
                            sx={{
                                textAlignLast: 'right',
                            }}
                        ></Grid>
                        <Grid
                            xs={4}
                            sx={{
                                textAlignLast: 'right',
                            }}
                        >
                            {existingUsers ? (
                                <>
                                    <Typography component="h1" variant="h5">
                                        {'Add Existing Users'}
                                    </Typography>
                                </>
                            ) : (
                                <Typography component="h1" variant="h5">
                                    {selectedStudent?.id
                                        ? 'Edit Student'
                                        : 'New Student'}
                                </Typography>
                            )}
                        </Grid>
                        <Grid
                            xs={4}
                            sx={{
                                textAlignLast: 'right',
                            }}
                        >
                            {allUsers?.length ? (
                                <>
                                    <Button
                                        onClick={() =>
                                            setExistingUsers(!existingUsers)
                                        }
                                        variant="contained"
                                    >
                                        {!existingUsers
                                            ? 'Select Existing Users'
                                            : 'Cancel'}
                                    </Button>
                                </>
                            ) : (
                                <></>
                            )}
                        </Grid>
                    </Grid>
                    {existingUsers ? (
                        <>
                            {allUsers?.length ? (
                                <>
                                    <Button
                                        onClick={bulkSaveExistingStudents}
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                    >
                                        Add Selected Students
                                    </Button>
                                    <MultiSelect
                                        title="Organization Users"
                                        options={userSelectOptionMapper(
                                            allUsers,
                                        )}
                                        maxHeight={600}
                                        dataHandler={(item: any) => {
                                            setSelectedUsers(item);
                                            setSelectedUsersRender(
                                                item?.map((user: any) => (
                                                    <p key={user?.key}>
                                                        {user?.option}
                                                    </p>
                                                )),
                                            );
                                        }}
                                    />

                                    {selectedUsersRender}
                                </>
                            ) : (
                                <></>
                            )}
                        </>
                    ) : (
                        <>
                            <Box
                                component="form"
                                onSubmit={handleSubmit}
                                sx={{ mt: 1 }}
                            >
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id={'firstName'}
                                    label="First Name"
                                    name={'firstName'}
                                    defaultValue={selectedStudent?.firstName}
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id={'lastName'}
                                    label="Last Name"
                                    name={'lastName'}
                                    defaultValue={selectedStudent?.lastName}
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id={'userEmail'}
                                    label={'Email'}
                                    name={'userEmail'}
                                    defaultValue={selectedStudent?.userEmail}
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name={'password'}
                                    label={'Password'}
                                    type={'password'}
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    Add Student
                                </Button>
                            </Box>
                        </>
                    )}
                </Box>
            </Card>
        </Modal>
    );
};
