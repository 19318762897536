import * as React from 'react';
import { ThreeDots } from 'react-loading-icons';
import './Loading.css';
const Loading = () => {
    return (
        <div className="Loading-container">
            <ThreeDots className="loading" stroke="#fff" fill="#fff" />
        </div>
    );
};

export default Loading;
