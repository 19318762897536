import * as React from 'react';
import AnchorIcon from '@mui/icons-material/Anchor';
import { useRef } from 'react';
import { useRichTextEditorContext } from 'mui-tiptap';
import MenuButton, { MenuButtonProps } from './MenuButton';

export type MenuButtonEditLinkProps = Partial<MenuButtonProps>;

export default function MenuButtonEditLink(props: MenuButtonEditLinkProps) {
    const editor = useRichTextEditorContext();
    const buttonRef = useRef<HTMLButtonElement | null>(null);
    return (
        <MenuButton
            buttonRef={buttonRef}
            tooltipLabel="Anchor"
            tooltipShortcutKeys={['mod', 'Shift', 'U']}
            IconComponent={AnchorIcon}
            selected={editor?.isActive('link')}
            disabled={!editor?.isEditable}
            onClick={() =>
                // When clicking the button to open the bubble menu, we'll place the
                // menu below the button
                editor?.commands.openLinkBubbleMenu({
                    anchorEl: buttonRef.current,
                    placement: 'bottom',
                })
            }
            {...props}
        />
    );
}
