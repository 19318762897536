import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {
    Button,
    Card,
    CssBaseline,
    Grid,
    IconButton,
    TextField,
} from '@mui/material';
import { AuthPost } from '../utilities/Routing/Requests';
import { toast } from 'react-toastify';
import { useGlobalStore } from '../utilities/GlobalState';
import { GlobalState } from '../interfaces/Interfaces';
import { BlobServiceClient } from '@azure/storage-blob';
import ImageUploader from './ImageUploader';
import { v1 as uuidv1 } from 'uuid';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ClearIcon from '@mui/icons-material/Clear';

export interface Props {
    open: boolean;
    handleClose: (refresh?: boolean) => void;
}

export const CreateModuleModal = ({ open, handleClose }: Props) => {
    const [file, setFile] = React.useState<Blob | null>(null);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [certifications, setCertifications] = React.useState<Array<string>>(
        [],
    );
    const [certificationsDetails, setCertificationsDetails] = React.useState<
        Array<string>
    >([]);
    const [outcomes, setOutcomes] = React.useState<Array<string>>([]);
    const [outcomesDetails, setOutcomesDetails] = React.useState<Array<string>>(
        [],
    );
    const [equipments, setEquipments] = React.useState<Array<string>>([]);
    const [equipmentsDetails, setEquipmentsDetails] = React.useState<
        Array<string>
    >([]);
    const globalState = useGlobalStore((state: GlobalState) => state);

    let localImageUrl = null;

    //Storage account credentials
    const account = globalState?.env?.account; // get the storage account name from the .env file
    const sasToken = globalState?.env?.sasToken; // get the SAS token from the .env file
    const containerName = globalState?.env?.containerName || ''; // get the container name from the .env file
    const blobServiceClient = new BlobServiceClient(
        `https://${account}.blob.core.windows.net/?${sasToken}`,
    ); // create a blobServiceClient
    const containerClient = blobServiceClient.getContainerClient(containerName); // create a containerClient

    const blobToFile = (blob: Blob) =>
        new File([blob], 'create', {
            type: 'image/png',
            lastModified: Date.now(),
        });

    const handleSubmitImage = (fileForSubmit: Blob): Promise<string> => {
        const fileToSubmit = blobToFile(fileForSubmit);
        const blobName = `${new Date().getTime()}-${
            fileToSubmit?.name || 'create.png'
        }`; // Specify a default blob name if needed
        if (!fileToSubmit) {
            // check if the file is selected
            // alert('Please select an image to upload');
            return new Promise((res) => res(''));
        }
        if (!account || !sasToken || !containerName) {
            // check if the credentials are set
            alert(
                'Please make sure you have set the Azure Storage credentials in the .env file or as ENV Variable',
            );
            return new Promise((res) => res(''));
        }
        try {
            const blobClient = containerClient.getBlockBlobClient(blobName); // get the blob client
            return blobClient
                .uploadData(fileToSubmit, {
                    blobHTTPHeaders: { blobContentType: fileToSubmit?.type },
                })
                .then(() => {
                    return `https://imageslms.blob.core.windows.net/images-lms/${blobName}`;
                });
        } catch (error: any) {
            toast.error(error?.toString());
            console.error(error); // Handle error
        }
        return new Promise((res) =>
            res(
                `https://imageslms.blob.core.windows.net/images-lms/${blobName}`,
            ),
        );
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        if (file) {
            handleSubmitImage(file).then((imageRes) => {
                const body = {
                    title: data.get('title'),
                    description: data.get('description'),
                    code: data.get('code'),
                    summary: data.get('summary'),
                    duration: data.get('duration'),
                    concepts: data.get('concepts'),
                    instructorNotes: data.get('instructorNotes'),
                    image: imageRes,
                    moduleGUID: uuidv1(),
                    certifications: JSON.stringify(certifications),
                    certificationsDetails: JSON.stringify(
                        certificationsDetails,
                    ),
                    outcomes: JSON.stringify(outcomes),
                    outcomesDetails: JSON.stringify(outcomesDetails),
                    equipments: JSON.stringify(equipments),
                    equipmentsDetails: JSON.stringify(equipmentsDetails),
                };
                AuthPost('/organization/createmodule', body, {
                    'x-access-token': globalState.authToken,
                })
                    .then((res) => {
                        if (res?.status === 200) {
                            toast.success('Module Successfully Created!');
                            handleClose(true);
                        }
                    })
                    .catch((err) => {
                        toast.error(err.toString());
                    });
            });
        } else {
            const body = {
                title: data.get('title'),
                description: data.get('description'),
                moduleGUID: uuidv1(),
                code: data.get('code'),
                summary: data.get('summary'),
                duration: data.get('duration'),
                concepts: data.get('concepts'),
                instructorNotes: data.get('instructorNotes'),
                certifications: JSON.stringify(certifications),
                certificationsDetails: JSON.stringify(certificationsDetails),
                outcomes: JSON.stringify(outcomes),
                outcomesDetails: JSON.stringify(outcomesDetails),
                equipments: JSON.stringify(equipments),
                equipmentsDetails: JSON.stringify(equipmentsDetails),
            };
            AuthPost('/organization/createmodule', body, {
                'x-access-token': globalState.authToken,
            })
                .then((res) => {
                    if (res?.status === 200) {
                        toast.success('Module Successfully Created!');
                        handleClose(true);
                    }
                })
                .catch((err) => {
                    toast.error(err.toString());
                });
        }
    };

    const changeImage = (newImage: string | null) => {
        localImageUrl = newImage;
    };

    const addCertificationInput = async () => {
        await setLoading(true);
        const modCerts: any = certifications;
        modCerts?.push('');
        await setCertifications(modCerts);

        const modCertsDeetz: any = certificationsDetails;
        modCertsDeetz?.push('');
        await setCertificationsDetails(modCertsDeetz);
        setLoading(false);
    };

    const onCertificationsInputChange = async (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        index: number,
    ) => {
        const originalCerts = certifications;
        let newCert = certifications?.[index];
        newCert = e.target.value;
        const newCerts = originalCerts.map((c: string, i: number) => {
            if (i !== index) {
                return c;
            } else {
                return newCert;
            }
        });
        await setCertifications(newCerts);
    };

    const onCertificationsDetailsInputChange = async (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        index: number,
    ) => {
        const originalCerts = certificationsDetails;
        let newCert = certificationsDetails[index];
        newCert = e.target.value;
        const newCerts = originalCerts.map((c: string, i: number) => {
            if (i !== index) {
                return c;
            } else {
                return newCert;
            }
        });
        await setCertificationsDetails(newCerts);
    };

    const onCertificationRemove = async (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        index: number,
    ) => {
        setLoading(true);
        const newCert = certifications.filter((q, i) => {
            return i !== index;
        });
        const newCertDeet = certificationsDetails.filter((q, i) => {
            return i !== index;
        });
        await setCertifications(newCert);
        await setCertificationsDetails(newCertDeet);
        setLoading(false);
    };

    const removeCertification = (ind: number) => {
        return (
            <IconButton
                onClick={(e: any) => {
                    onCertificationRemove(e, ind);
                }}
            >
                <ClearIcon />
            </IconButton>
        );
    };

    const mapCertifications = (mapCerts: any) => {
        return (
            <Card sx={{ marginBottom: '16px' }}>
                <Grid container>
                    <Grid xs={1}>
                        <IconButton
                            key={'add-answers-iconbutton'}
                            onClick={addCertificationInput}
                        >
                            <AddCircleIcon
                                sx={{
                                    width: '50px',
                                    height: '50px',
                                    color: 'green',
                                }}
                            />
                        </IconButton>
                    </Grid>
                    <Grid xs={11} sx={{ alignContent: 'center' }}>
                        <Typography>{'Add Certification'}</Typography>
                    </Grid>
                </Grid>
                {mapCerts?.map((cert: any, i: number) => {
                    return (
                        <>
                            <Grid
                                container
                                sx={{
                                    padding: '10px',
                                }}
                            >
                                <Grid xs={1} sx={{ alignContent: 'center' }}>
                                    <Typography
                                        sx={{
                                            verticalAlign: 'middle',
                                            textAlign: 'center',
                                        }}
                                    >
                                        {i + 1 + ') '}
                                    </Typography>
                                </Grid>
                                <Grid xs={11}>
                                    <div
                                        style={{
                                            padding: '10px',
                                        }}
                                    >
                                        <TextField
                                            fullWidth
                                            name="certifications"
                                            label="Certification"
                                            type="text"
                                            defaultValue={cert}
                                            onChange={(e) =>
                                                onCertificationsInputChange(
                                                    e,
                                                    i,
                                                )
                                            }
                                            InputProps={{
                                                endAdornment:
                                                    removeCertification(i),
                                            }}
                                        />
                                    </div>
                                </Grid>
                                <Grid xs={12}>
                                    <div style={{ padding: '10px' }}>
                                        <TextField
                                            fullWidth
                                            defaultValue={
                                                certificationsDetails[i]
                                            }
                                            name="certificationsDetails"
                                            label="Certification Detail"
                                            type="text"
                                            onChange={(e) =>
                                                onCertificationsDetailsInputChange(
                                                    e,
                                                    i,
                                                )
                                            }
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </>
                    );
                })}
            </Card>
        );
    };

    const addOutcomeInput = async () => {
        await setLoading(true);
        const modOutcomes: any = outcomes;
        modOutcomes?.push('');
        await setOutcomes(modOutcomes);

        const modCertsDeetz: any = outcomesDetails;
        modCertsDeetz?.push('');
        await setOutcomesDetails(modCertsDeetz);
        setLoading(false);
    };

    const onOutcomesInputChange = async (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        index: number,
    ) => {
        const originalOutcomes = outcomes;
        let newOutcome = outcomes?.[index];
        newOutcome = e.target.value;
        const newOutcomes = originalOutcomes.map((c: string, i: number) => {
            if (i !== index) {
                return c;
            } else {
                return newOutcome;
            }
        });
        await setOutcomes(newOutcomes);
    };

    const onOutcomesDetailsInputChange = async (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        index: number,
    ) => {
        const originalOutcomes = outcomesDetails;
        let newCert = outcomesDetails[index];
        newCert = e.target.value;
        const newCerts = originalOutcomes.map((c: string, i: number) => {
            if (i !== index) {
                return c;
            } else {
                return newCert;
            }
        });
        await setOutcomesDetails(newCerts);
    };

    const onOutcomeRemove = async (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        index: number,
    ) => {
        setLoading(true);
        const newOutcome = outcomes.filter((q, i) => {
            return i !== index;
        });
        const newOutcomeDeet = outcomesDetails.filter((q, i) => {
            return i !== index;
        });
        await setOutcomes(newOutcome);
        await setOutcomesDetails(newOutcomeDeet);
        setLoading(false);
    };

    const removeOutcome = (ind: number) => {
        return (
            <IconButton
                onClick={(e: any) => {
                    onOutcomeRemove(e, ind);
                }}
            >
                <ClearIcon />
            </IconButton>
        );
    };

    const mapOutcomes = (outcomesToMap: any) => {
        return (
            <Card sx={{ marginBottom: '16px' }}>
                <Grid container>
                    <Grid xs={1}>
                        <IconButton
                            key={'add-answers-iconbutton'}
                            onClick={addOutcomeInput}
                        >
                            <AddCircleIcon
                                sx={{
                                    width: '50px',
                                    height: '50px',
                                    color: 'green',
                                }}
                            />
                        </IconButton>
                    </Grid>
                    <Grid xs={11} sx={{ alignContent: 'center' }}>
                        <Typography>{'Add Outcome'}</Typography>
                    </Grid>
                </Grid>
                {outcomesToMap?.map((outs: any, i: number) => {
                    return (
                        <>
                            <Grid
                                container
                                sx={{
                                    padding: '10px',
                                }}
                            >
                                <Grid xs={1} sx={{ alignContent: 'center' }}>
                                    <Typography
                                        sx={{
                                            verticalAlign: 'middle',
                                            textAlign: 'center',
                                        }}
                                    >
                                        {i + 1 + ') '}
                                    </Typography>
                                </Grid>
                                <Grid xs={11}>
                                    <div
                                        style={{
                                            padding: '10px',
                                        }}
                                    >
                                        <TextField
                                            fullWidth
                                            name="outcomes"
                                            label="Outcome"
                                            type="text"
                                            defaultValue={outs}
                                            onChange={(e) =>
                                                onOutcomesInputChange(e, i)
                                            }
                                            InputProps={{
                                                endAdornment: removeOutcome(i),
                                            }}
                                        />
                                    </div>
                                </Grid>
                                <Grid xs={12}>
                                    <div style={{ padding: '10px' }}>
                                        <TextField
                                            fullWidth
                                            defaultValue={outcomesDetails[i]}
                                            name="outcomesDetails"
                                            label="Outcome Detail"
                                            type="text"
                                            onChange={(e) =>
                                                onOutcomesDetailsInputChange(
                                                    e,
                                                    i,
                                                )
                                            }
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </>
                    );
                })}
            </Card>
        );
    };

    const addEquipmentInput = async () => {
        await setLoading(true);
        const modEquipments: any = equipments;
        modEquipments?.push('');
        await setEquipments(modEquipments);

        const modCertsDeetz: any = equipmentsDetails;
        modCertsDeetz?.push('');
        await setEquipmentsDetails(modCertsDeetz);
        setLoading(false);
    };

    const onEquipmentsInputChange = async (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        index: number,
    ) => {
        const originalEquipments = equipments;
        let newEquipment = equipments?.[index];
        newEquipment = e.target.value;
        const newEquipments = originalEquipments.map((c: string, i: number) => {
            if (i !== index) {
                return c;
            } else {
                return newEquipment;
            }
        });
        await setEquipments(newEquipments);
    };

    const onEquipmentsDetailsInputChange = async (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        index: number,
    ) => {
        const originalEquipments = equipmentsDetails;
        let newCert = equipmentsDetails[index];
        newCert = e.target.value;
        const newCerts = originalEquipments.map((c: string, i: number) => {
            if (i !== index) {
                return c;
            } else {
                return newCert;
            }
        });
        await setEquipmentsDetails(newCerts);
    };

    const onEquipmentRemove = async (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        index: number,
    ) => {
        setLoading(true);
        const newEquipment = equipments.filter((q, i) => {
            return i !== index;
        });
        const newEquipmentDeet = equipmentsDetails.filter((q, i) => {
            return i !== index;
        });
        await setEquipments(newEquipment);
        await setEquipmentsDetails(newEquipmentDeet);
        setLoading(false);
    };

    const removeEquipment = (ind: number) => {
        return (
            <IconButton
                onClick={(e: any) => {
                    onEquipmentRemove(e, ind);
                }}
            >
                <ClearIcon />
            </IconButton>
        );
    };

    const mapEquipments = (equipmentsToMap: any) => {
        return (
            <Card sx={{ marginBottom: '16px' }}>
                <Grid container>
                    <Grid xs={1}>
                        <IconButton
                            key={'add-answers-iconbutton'}
                            onClick={addEquipmentInput}
                        >
                            <AddCircleIcon
                                sx={{
                                    width: '50px',
                                    height: '50px',
                                    color: 'green',
                                }}
                            />
                        </IconButton>
                    </Grid>
                    <Grid xs={11} sx={{ alignContent: 'center' }}>
                        <Typography>{'Add Equipment'}</Typography>
                    </Grid>
                </Grid>
                {equipmentsToMap?.map((outs: any, i: number) => {
                    return (
                        <>
                            <Grid
                                container
                                sx={{
                                    padding: '10px',
                                }}
                            >
                                <Grid xs={1} sx={{ alignContent: 'center' }}>
                                    <Typography
                                        sx={{
                                            verticalAlign: 'middle',
                                            textAlign: 'center',
                                        }}
                                    >
                                        {i + 1 + ') '}
                                    </Typography>
                                </Grid>
                                <Grid xs={11}>
                                    <div
                                        style={{
                                            padding: '10px',
                                        }}
                                    >
                                        <TextField
                                            fullWidth
                                            name="equipments"
                                            label="Equipment"
                                            type="text"
                                            defaultValue={outs}
                                            onChange={(e) =>
                                                onEquipmentsInputChange(e, i)
                                            }
                                            InputProps={{
                                                endAdornment:
                                                    removeEquipment(i),
                                            }}
                                        />
                                    </div>
                                </Grid>
                                <Grid xs={12}>
                                    <div style={{ padding: '10px' }}>
                                        <TextField
                                            fullWidth
                                            defaultValue={equipmentsDetails[i]}
                                            name="equipmentsDetails"
                                            label="Equipment Detail"
                                            type="text"
                                            onChange={(e) =>
                                                onEquipmentsDetailsInputChange(
                                                    e,
                                                    i,
                                                )
                                            }
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </>
                    );
                })}
            </Card>
        );
    };

    return (
        <Modal
            open={open}
            onClose={() => handleClose()}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Card sx={{ width: '800px', margin: '10px', overflow: 'auto' }}>
                <CssBaseline />
                <Box
                    sx={{
                        margin: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5">
                        Create Module
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        sx={{ mt: 1, alignItems: 'center' }}
                    >
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="title"
                            label="Title"
                            name="title"
                            autoFocus
                        />
                        <TextField
                            fullWidth
                            label="Description"
                            multiline={true}
                            minRows={3}
                            required
                            id="description"
                            name="description"
                            aria-label="Description"
                            placeholder="Description *"
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="code"
                            label="Code"
                            name="code"
                        />
                        <TextField
                            fullWidth
                            label="Description"
                            multiline={true}
                            required
                            id="summary"
                            name="summary"
                            aria-label="Summary"
                            minRows={3}
                            placeholder="Summary *"
                        />
                        <TextField
                            minRows={3}
                            fullWidth
                            placeholder="Instructor Notes"
                            label="Instructor Notes"
                            multiline={true}
                            sx={{ marginBottom: '16px' }}
                            id="instructorNotes"
                            name="instructorNotes"
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="duration"
                            label="Duration"
                            name="duration"
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="concepts"
                            label="Concepts"
                            name="concepts"
                        />
                        {!loading && mapCertifications(certifications)}
                        {!loading && mapOutcomes(outcomes)}
                        {!loading && mapEquipments(equipments)}
                        <ImageUploader
                            setFile={(newFile) => setFile(newFile)}
                            imageUrl={localImageUrl}
                            file={file}
                            changeUrl={changeImage}
                            deleteCallback={() => {}}
                        />
                        {/* <Button
                            name="image"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            onClick={addImage}
                        >
                            COVER IMAGE
                        </Button> */}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            CREATE MODULE
                        </Button>
                    </Box>
                </Box>
            </Card>
        </Modal>
    );
};
