'use client';
import * as React from 'react';
// import { useErrorBoundary } from 'react-error-boundary';

export const ErrorFallback = ({ error, resetErrorBoundary }: any) => {
    // const { resetBoundary } = useErrorBoundary();

    return (
        <div role="alert">
            <p>Something went wrong:</p>
            <pre style={{ color: 'red' }}>{error?.message}</pre>
            <button onClick={resetErrorBoundary}>Try again</button>
        </div>
    );
};
