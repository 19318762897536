import * as React from 'react';
import Card from '@mui/material/Card';
import { Class } from '../interfaces/Interfaces';

interface Props {
    handleToggleModulePage: () => void;
    module: Class;
}

const ClassPreviewCard = ({ handleToggleModulePage, module }: Props) => {
    const backImg = module?.image?.length ? module.image : '';
    return (
        <Card
            onClick={handleToggleModulePage}
            style={{
                backgroundImage: 'url(' + backImg + ')',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'bottom',
                boxShadow:
                    '0px 2px 11px -1px rgba(0,0,0,4.8),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
            }}
            sx={{
                margin: '10px',
                width: '200px',
                height: '300px',
                display: 'flex',
                flexDirection: 'column',
                // backgroundImage: backImg as string,
            }}
        >
            <div
                style={{
                    margin: '10px 10px 10px 10px',
                    fontWeight: 'bolder',
                    textAlign: 'center',
                }}
            >
                {module?.className}
            </div>
            <div
                style={{
                    margin: '10px 10px 10px 10px',
                    height: '50px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textAlign: 'center',
                }}
                title={module?.classDescription}
            >
                {module?.classDescription}
            </div>
            <div
                style={{
                    backgroundImage: backImg as string,
                }}
            />
        </Card>
    );
};

export { ClassPreviewCard };
