import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { defaultTheme } from '../utilities/Utilites';
import Box from '@mui/system/Box';
import { Card, Grid } from '@mui/material';
import packageJson from '../../package.json';
import '../styles/UserAgentInfo.css';
import { getUserAgentDetails } from '../utilities/Utilites';

export const About = () => {
    const [details, setDetails] = useState<any>({});
    useEffect(() => {
        const userDetails = getUserAgentDetails();
        setDetails(userDetails);
    }, []);

    return (
        <ThemeProvider theme={defaultTheme}>
            <Box
                sx={{
                    padding: '30px 10px 10px 10px',
                    margin: '10px 10px 10px 10px',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Card
                    sx={{
                        maxWidth: '100%',
                        padding: '20px',
                        textAlign: 'center',
                    }}
                >
                    <div className="user-agent-info">
                        <Grid container sx={{ margin: 0 }} spacing={2}>
                            <Grid
                                item
                                sx={{
                                    padding: '16px',
                                    margin: 'auto',
                                }}
                                xs={6}
                                md={6}
                            >
                                <div className="info-card">
                                    <h2>{'About Industry Training Academy'}</h2>
                                    {`Version: ${packageJson.version}`}
                                    <p></p>
                                    <h2>Browser</h2>
                                    <p>Name: {details?.browser?.name}</p>
                                    <p>Version: {details?.browser?.version}</p>
                                    <p>Major: {details?.browser?.major}</p>
                                    <p>
                                        Type: {details?.browser?.type || 'N/A'}
                                    </p>
                                    <p></p>
                                    <h2>Engine</h2>
                                    <p>Name: {details?.engine?.name}</p>
                                    <p>Version: {details?.engine?.version}</p>
                                </div>
                            </Grid>
                            <Grid
                                item
                                sx={{ padding: '16px', margin: 'auto' }}
                                md={6}
                                xs={6}
                            >
                                <div className="info-card">
                                    <h2>Operating System</h2>
                                    <p>Name: {details?.os?.name}</p>
                                    <p>Version: {details?.os?.version}</p>
                                    <p></p>
                                    <h2>Device</h2>
                                    <p>
                                        Model: {details?.device?.model || 'N/A'}
                                    </p>
                                    <p>
                                        Type: {details?.device?.type || 'N/A'}
                                    </p>
                                    <p>
                                        Vendor:{' '}
                                        {details?.device?.vendor || 'N/A'}
                                    </p>
                                    <p></p>
                                    <h2>CPU</h2>
                                    <p>
                                        Architecture:{' '}
                                        {details?.cpu?.architecture}
                                    </p>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Card>
            </Box>
        </ThemeProvider>
    );
};
