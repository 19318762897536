import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button, Card, CssBaseline, TextField } from '@mui/material';
import { AuthPost } from '../utilities/Routing/Requests';
import { toast } from 'react-toastify';
import { useGlobalStore } from '../utilities/GlobalState';
import { v1 as uuidv1 } from 'uuid';
import { MuiPhone } from '../utilities/PhoneInput/MUIPhone';
import { GetCity } from 'react-country-state-city';
import { SearchableDropdown } from '../utilities/SearchableDropdown';

export interface Props {
    open: boolean;
    handleClose: (refresh?: boolean) => void;
    refreshOrgList: () => void;
    stateList: any;
    cityList: any;
    setCityList: any;
    selectedCity: any;
    selectedState: any;
    setSelectedState: any;
    setSelectedCity: any;
}

export const CreateOrganizationModal = ({
    open,
    handleClose,
    refreshOrgList,
    stateList,
    cityList,
    setCityList,
    selectedCity,
    selectedState,
    setSelectedState,
    setSelectedCity,
}: Props) => {
    const [phone, setPhone] = React.useState('');
    const [contactPhone, setContactPhone] = React.useState('');

    // const [selectedState, setSelectedState] = React.useState<any>();
    // const [stateList, setStateList] = React.useState([]);

    // const [selectedCity, setSelectedCity] = React.useState<any>();
    // const [cityList, setCityList] = React.useState([]);

    const globalState = useGlobalStore((state) => state);
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const body = {
            orgGUID: uuidv1(),
            name: data.get('name'),
            phone: data.get('phone'),
            type: data.get('type'),
            parent: data.get('parent') || '',
            billType: data.get('billType') || '',
            reseller: data.get('reseller') || '',
            addressLine1: data.get('addressLine1'),
            addressLine2: data.get('addressLine2') || '',
            city: selectedCity?.name,
            state: selectedState?.state_code,
            zipcode: data.get('zipcode'),
            firstName: data.get('firstName'),
            lastName: data.get('lastName'),
            contactPhone: data.get('contactPhone'),
            email: data.get('email'),
            password: data.get('password'),
            active: true,
        };
        AuthPost('/organization/createorg', body, {
            'x-access-token': globalState.authToken,
        })
            .then((res) => {
                if (res?.status === 200) {
                    toast.success('Organization Successfully Created!');
                    refreshOrgList();
                    handleClose(true);
                }
            })
            .catch((err) => {
                toast.error(err.toString());
            });
    };
    return (
        <Modal
            open={open}
            onClose={() => handleClose()}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Card sx={{ maxWidth: '800px', margin: '10px', overflow: 'auto' }}>
                <CssBaseline />
                <Box
                    sx={{
                        margin: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5">
                        Create Organization
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        sx={{ mt: 1 }}
                    >
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="name"
                            label="Organization Name"
                            name="name"
                            autoFocus
                        />
                        <MuiPhone
                            value={phone}
                            onChange={(e) => setPhone(e)}
                            margin="normal"
                            required
                            fullWidth
                            id="phone"
                            label="Phone"
                            name="phone"
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="type"
                            label="Type"
                            name="type"
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            id="parent"
                            label="Parent Organization"
                            name="parent"
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            id="billType"
                            label="Billing Type"
                            name="billType"
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            id="reseller"
                            label="Reseller"
                            name="reseller"
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="addressLine1"
                            label="Address Line 1"
                            name="addressLine1"
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            id="addressLine2"
                            label="Address Line 2"
                            name="addressLine2"
                        />
                        <div>
                            <SearchableDropdown
                                label="State"
                                selected={selectedState}
                                allOptions={stateList}
                                searchKey="name"
                                callBackOnSelect={(selectedOption: any) => {
                                    setSelectedState(selectedOption);
                                    GetCity(233, selectedOption?.id).then(
                                        (result: any) => {
                                            setCityList(result);
                                        },
                                    );
                                }}
                            />
                            {cityList?.length ? (
                                <SearchableDropdown
                                    label="City"
                                    selected={selectedCity}
                                    allOptions={cityList}
                                    searchKey="name"
                                    callBackOnSelect={(selectedOption: any) => {
                                        setSelectedCity(selectedOption);
                                    }}
                                />
                            ) : (
                                <></>
                            )}
                        </div>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="zipcode"
                            label="Zip Code"
                            name="zipcode"
                            onInput={(e: any) => {
                                e.target.value = Math.max(
                                    0,
                                    parseInt(e.target.value),
                                )
                                    .toString()
                                    .slice(0, 5);
                            }}
                            type="number"
                        />
                        <Box
                            sx={{
                                margin: '20px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Typography variant="h5">
                                Primary Contact
                            </Typography>
                        </Box>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="firstName"
                            label="First Name"
                            name="firstName"
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="lastName"
                            label="Last Name"
                            name="lastName"
                        />
                        <MuiPhone
                            value={contactPhone}
                            onChange={(e) => setContactPhone(e)}
                            margin="normal"
                            required
                            fullWidth
                            id="contactPhone"
                            label="Contact Phone"
                            name="contactPhone"
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            CREATE ORGANIZATION
                        </Button>
                    </Box>
                </Box>
            </Card>
        </Modal>
    );
};
