import * as React from 'react';
import Box from '@mui/material/Box';
import { IconButton } from '@mui/material';
import { Datagrid } from '../utilities/Datagrid';
import { Module, ModulePage } from '../interfaces/Interfaces';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    GridRenderCellParams,
    gridDetailPanelExpandedRowsContentCacheSelector,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid-pro';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileCopyIcon from '@mui/icons-material/FileCopy';

interface Props {
    handleToggleCreateModulePage: (edit?: boolean, newType?: string) => void;
    module: Module;
    setPages: (pages: Array<ModulePage>) => void;
    pages: Array<ModulePage>;
    toggleEditPage: (page: ModulePage) => void;
    getModulePages: (moduleId: number) => Promise<Array<ModulePage>>;
    toggleDeletePageModal: (id: number, refresh?: boolean) => void;
}

const ModulePageEditor = ({
    handleToggleCreateModulePage,
    module,
    pages,
    setPages,
    getModulePages,
    toggleEditPage,
    toggleDeletePageModal,
}: Props) => {
    const sortPages = (a: ModulePage, b: ModulePage) => {
        const itemA = Number(a?.pageNumber || 1);
        const itemB = Number(b?.pageNumber || 1);

        return itemA - itemB;
    };
    React.useEffect(() => {
        getModulePages(module?.id).then((res) => {
            setPages(res.sort(sortPages));
        });
    }, []);

    // const sortedPages = pages.sort((a, b) => {
    //     if (a?.pageNumber && b?.pageNumber) {
    //         if (a?.pageNumber < b?.pageNumber) {
    //             return -1;
    //         }
    //         if (a?.pageNumber > b?.pageNumber) {
    //             return 1;
    //         }
    //     }
    //     return 0;
    // });

    // const getDetailPanelContent = () => {
    //     const pages = getModulePages(module?.id);
    //     return (
    //         <div>
    //             {pages?.map((item: any) => {
    //                 console.log('item', item);
    //                 return (
    //                     <Box
    //                         key={item?.id}
    //                         sx={{ p: 2 }}
    //                     >{`Order #${item?.id}`}</Box>
    //                 );
    //             })}
    //         </div>
    //     );
    // };

    function CustomDetailPanelToggle(
        props: Pick<GridRenderCellParams, 'id' | 'value'>,
    ) {
        const { id, value: isExpanded } = props;
        const apiRef = useGridApiContext();

        // To avoid calling ´getDetailPanelContent` all the time, the following selector
        // gives an object with the detail panel content for each row id.
        const contentCache = useGridSelector(
            apiRef,
            gridDetailPanelExpandedRowsContentCacheSelector,
        );

        // If the value is not a valid React element, it means that the row has no detail panel.
        const hasDetail = React.isValidElement(contentCache[id]);

        return (
            <IconButton
                size="small"
                tabIndex={-1}
                disabled={!hasDetail}
                aria-label={isExpanded ? 'Close' : 'Open'}
            >
                <ExpandMoreIcon
                    sx={{
                        transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
                        transition: (theme) =>
                            theme.transitions.create('transform', {
                                duration: theme.transitions.duration.shortest,
                            }),
                    }}
                    fontSize="inherit"
                />
            </IconButton>
        );
    }

    const getDetailPanelHeight = React.useCallback(() => 50, []);
    return (
        <Box
        // sx={{
        //     padding: '30px 10px 10px 10px',
        //     margin: '10px 10px 10px 10px',
        //     maxWidth: '100vp',
        //     display: 'flex',
        //     flexDirection: 'column',
        // }}
        >
            {pages?.length ? (
                Datagrid({
                    sx: { padding: '10px' },
                    // getDetailPanelContent: getDetailPanelContent,
                    getDetailPanelHeight: getDetailPanelHeight,
                    columns: [
                        {
                            // field: 'contentItems',
                            headerName: 'Pages',
                            type: 'string',
                            flex: 0.25,
                            // editable: true,
                            ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
                            renderCell: (params) => {
                                return (
                                    <CustomDetailPanelToggle
                                        id={params.id}
                                        value={params.value}
                                    />
                                );
                            },
                        },
                        // {
                        //     field: 'id',
                        //     headerName: 'ID',
                        //     type: 'string',
                        //     flex: 0.25,
                        // },
                        // {
                        //     field: 'data',
                        //     headerName: 'Raw Data',
                        //     type: 'string',
                        //     flex: 1,
                        // },
                        {
                            field: 'pageNumber',
                            headerName: 'Page Number',
                            type: 'number',
                            flex: 0.25,
                            // editable: true,
                        },
                        {
                            field: 'title',
                            headerName: 'Title',
                            type: 'string',
                            flex: 1,
                        },
                        {
                            field: 'action',
                            headerName: 'Action',
                            flex: 0.5,
                            sortable: false,
                            renderCell: (params) => {
                                return (
                                    <>
                                        <IconButton
                                            sx={{
                                                marginLeft: '5px',
                                                backgroundColor: '#4169E1',
                                                border: 'solid #fff 1px',
                                                color: '#fff',
                                                fontWeight: 'bold',
                                                fontSize: '20px',
                                            }}
                                            title={'Duplicate Page'}
                                            onClick={() => {
                                                toggleEditPage(params?.row);
                                                handleToggleCreateModulePage(
                                                    true,
                                                );
                                            }}
                                        >
                                            <FileCopyIcon />
                                        </IconButton>
                                        <IconButton
                                            sx={{
                                                marginLeft: '5px',
                                                backgroundColor: '#f1c40f',
                                                border: 'solid #fff 1px',
                                                color: '#fff',
                                                fontWeight: 'bold',
                                                fontSize: '20px',
                                            }}
                                            title={'Edit Page'}
                                            onClick={() => {
                                                toggleEditPage(params?.row);
                                                // handleToggleCreateModulePage(
                                                //     true,
                                                // );
                                            }}
                                        >
                                            <SettingsApplicationsIcon />
                                        </IconButton>
                                        <IconButton
                                            sx={{
                                                marginLeft: '5px',
                                                backgroundColor: '#e74c3c',
                                                border: 'solid #fff 1px',
                                                color: '#fff',
                                                fontWeight: 'bold',
                                                fontSize: '20px',
                                            }}
                                            title={'Delete Page'}
                                            onClick={() =>
                                                toggleDeletePageModal(
                                                    params?.row?.id,
                                                    true,
                                                )
                                            }
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </>
                                );
                            },
                        },
                    ],
                    data: pages || [],
                })
            ) : (
                <></>
            )}
        </Box>
    );
};

export { ModulePageEditor };
