import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button, Card, CssBaseline, TextField } from '@mui/material';
import { AuthPost } from '../utilities/Routing/Requests';
import { toast } from 'react-toastify';
import { ApiOrganization } from '../interfaces/Interfaces';

export interface Props {
    open: boolean;
    handleClose: (refresh?: boolean) => void;
    organization: ApiOrganization;
    authToken: string;
}

export const AddContactModal = ({
    open,
    handleClose,
    organization,
    authToken,
}: Props) => {
    const handleSubmit = (event: any) => {
        event.preventDefault();
        let orgContactIds = '';
        if (organization?.contacts) {
            organization?.contacts?.forEach(
                (contact) =>
                    (orgContactIds =
                        orgContactIds + contact?.id.toString() + ','),
            );
        }
        const data = new FormData(event.currentTarget);
        const userBody = {
            UserEmail: data.get('email'),
            FirstName: data.get('firstName'),
            LastName: data.get('lastName'),
            Phone: data.get('phone'),
            Type: data.get('type'),
            AddToOrg: organization?.id,
            CurrentOrgContacts: orgContactIds,
            // addressActive: data.get('true'),
            // active: data.get('true'),
        };
        AuthPost('/organization/createcontact', userBody, {
            'x-access-token': authToken,
        })
            .then((contactRes) => {
                if (
                    contactRes?.status === 200 ||
                    contactRes?.status === 201 ||
                    contactRes?.status === 204
                ) {
                    toast.success('Contact Created Successfully!');
                    handleClose(true);
                }
            })
            .catch((err) => {
                toast.error(err.toString());
            });
        handleClose(true);
    };

    return (
        <Modal
            open={open}
            onClose={() => handleClose()}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Card sx={{ width: '800px', margin: '10px', overflow: 'auto' }}>
                <CssBaseline />
                <Box
                    sx={{
                        margin: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5">
                        Add Contact
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        sx={{ mt: 1 }}
                    >
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id={'type'}
                            label="Type"
                            name={'type'}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id={'firstName'}
                            label="First Name"
                            name={'firstName'}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id={'lastName'}
                            label="Last Name"
                            name={'lastName'}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id={'email'}
                            label={'Email'}
                            name={'email'}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id={'phone'}
                            label={'Phone Number'}
                            name={'phone'}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Add Contact
                        </Button>
                    </Box>
                </Box>
            </Card>
        </Modal>
    );
};
