import * as React from 'react';
import { IconButton, useTheme } from '@mui/material';
import {
    ImageNodeAttributes,
    MenuButtonAddTable,
    MenuButtonBlockquote,
    MenuButtonBold,
    MenuButtonBulletedList,
    MenuButtonCode,
    MenuButtonCodeBlock,
    MenuButtonEditLink,
    MenuButtonHighlightColor,
    MenuButtonHorizontalRule,
    MenuButtonImageUpload,
    MenuButtonIndent,
    MenuButtonItalic,
    MenuButtonOrderedList,
    MenuButtonRedo,
    MenuButtonRemoveFormatting,
    MenuButtonStrikethrough,
    MenuButtonSubscript,
    MenuButtonSuperscript,
    MenuButtonTaskList,
    MenuButtonTextColor,
    MenuButtonUnderline,
    MenuButtonUndo,
    MenuButtonUnindent,
    MenuControlsContainer,
    MenuDivider,
    MenuSelectFontFamily,
    MenuSelectFontSize,
    MenuSelectHeading,
    MenuSelectTextAlign,
    RichTextEditorRef,
    insertImages,
    isTouchDevice,
} from 'mui-tiptap';

import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import MenuButtonEditAnchor from './MenuButtonEditAnchor';

export interface Props {
    rteRef: React.RefObject<RichTextEditorRef>;
    handleSubmitImage: (fileToSubmit: File) => Promise<string>;
}

export default function EditorMenuControls({
    rteRef,
    handleSubmitImage,
}: Props) {
    const theme = useTheme();
    const handleNewImageFiles = async (
        files: File[],
    ): Promise<Array<ImageNodeAttributes>> => {
        const attributesForImageFiles: Array<ImageNodeAttributes> = [];
        files.forEach((fileAttr) => {
            handleSubmitImage(fileAttr).then((res) => {
                attributesForImageFiles.push({
                    src: res || '',
                    alt: fileAttr.name,
                });
            });
        });

        setTimeout(() => {
            insertImages({
                images: attributesForImageFiles,
                editor: rteRef?.current?.editor || null,
                // position: insertPosition,
            });
        }, 1000);
        return attributesForImageFiles;
    };

    // const widthRef = React.useRef(null);
    // const heightRef = React.useRef(null);

    // React.useEffect(() => {
    //     if (widthRef.current && heightRef.current) {
    //     widthRef.current.value = 640
    //     heightRef.current.value = 480
    //     }
    // }, [widthRef.current, heightRef.current])

    const addYoutubeVideo = () => {
        const url = prompt('Enter YouTube URL');

        if (url && rteRef?.current?.editor) {
            rteRef?.current?.editor.commands.setYoutubeVideo({
                src: url,
                // width: Math.max(320, parseInt(widthRef?.current?.value, 10)) || 640,
                // height: Math.max(180, parseInt(heightRef?.current?.value, 10)) || 480,
                width: 640,
                height: 480,
            });
        }
    };

    return (
        <MenuControlsContainer>
            <MenuSelectFontFamily
                options={[
                    { label: 'Cursive', value: 'cursive' },
                    { label: 'Monospace', value: 'monospace' },
                    { label: 'Serif', value: 'serif' },
                    { label: 'Sans-serif', value: 'sans-serif' },
                    { label: 'Monospace', value: 'monospace' },
                    { label: 'Cursive', value: 'cursive' },
                    { label: 'Fantasy', value: 'fantasy' },
                    { label: 'System-ui', value: 'system-ui' },
                    { label: 'Ui-serif', value: 'ui-serif' },
                    { label: 'Ui-sans-serif', value: 'ui-sans-serif' },
                    { label: 'Ui-monospace', value: 'ui-monospace' },
                    { label: 'Ui-rounded', value: 'ui-rounded' },
                    { label: 'Math', value: 'math' },
                    { label: 'Fangsong', value: 'fangsong' },
                ]}
            />

            <MenuDivider />

            <MenuSelectHeading />

            <MenuDivider />

            <MenuSelectFontSize />

            <MenuDivider />

            <MenuButtonBold />

            <MenuButtonItalic />

            <MenuButtonUnderline />

            <MenuButtonStrikethrough />

            <MenuButtonSubscript />

            <MenuButtonSuperscript />

            <MenuDivider />

            <MenuButtonTextColor
                defaultTextColor={theme.palette.text.primary || 'black'}
                swatchColors={[
                    { value: '#000000', label: 'Black' },
                    { value: '#ffffff', label: 'White' },
                    { value: '#888888', label: 'Grey' },
                    { value: '#ff0000', label: 'Red' },
                    { value: '#ff9900', label: 'Orange' },
                    { value: '#ffff00', label: 'Yellow' },
                    { value: '#00d000', label: 'Green' },
                    { value: '#0000ff', label: 'Blue' },
                ]}
            />

            <MenuButtonHighlightColor
                defaultMarkColor={'#ffff00'}
                swatchColors={[
                    { value: '#595959', label: 'Dark grey' },
                    { value: '#dddddd', label: 'Light grey' },
                    { value: '#ffa6a6', label: 'Light red' },
                    { value: '#ffd699', label: 'Light orange' },
                    // Plain yellow matches the browser default `mark` like when using Cmd+Shift+H
                    { value: '#ffff00', label: 'Yellow' },
                    { value: '#99cc99', label: 'Light green' },
                    { value: '#90c6ff', label: 'Light blue' },
                    { value: '#8085e9', label: 'Light purple' },
                ]}
            />

            <MenuDivider />

            <MenuButtonEditLink />

            <MenuButtonEditAnchor />

            <MenuDivider />

            <MenuSelectTextAlign />

            <MenuDivider />

            <MenuButtonOrderedList />

            <MenuButtonBulletedList />

            <MenuButtonTaskList />

            {/* On touch devices, we'll show indent/unindent buttons, since they're
      unlikely to have a keyboard that will allow for using Tab/Shift+Tab. These
      buttons probably aren't necessary for keyboard users and would add extra
      clutter. */}
            {isTouchDevice() && (
                <>
                    <MenuButtonIndent />

                    <MenuButtonUnindent />
                </>
            )}

            <MenuDivider />

            <MenuButtonBlockquote />

            <MenuDivider />

            <MenuButtonCode />

            <MenuButtonCodeBlock />

            <MenuDivider />

            <MenuButtonImageUpload
                onUploadFiles={(files) => handleNewImageFiles(files)}
                inputProps={{
                    multiple: false,
                    accept: '.odt,.pdf,.xls,.xlsx,.csv,.pptx,.ppt,.txt,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/*',
                }}
                tooltipLabel="Upload Image or Document"
            />

            <MenuDivider />

            <MenuButtonHorizontalRule />

            <MenuButtonAddTable />

            <MenuDivider />

            <MenuButtonRemoveFormatting />

            <MenuDivider />
            <IconButton onClick={addYoutubeVideo}>
                <VideoLibraryIcon />
            </IconButton>
            <MenuDivider />

            <MenuButtonUndo />
            <MenuButtonRedo />
        </MenuControlsContainer>
    );
}
