import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button, Card, CssBaseline } from '@mui/material';

export interface Props {
    open: boolean;
    handleClose: (id?: number | undefined) => void;
    title: string;
    confirmationText: string;
    confirmationButtonText: string;
    confirmationButtonAction: () => void;
}

export const ConfirmationModal = ({
    open,
    handleClose,
    title,
    confirmationText,
    confirmationButtonText,
    confirmationButtonAction,
}: Props) => {
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
    };
    return (
        <Modal
            open={open}
            onClose={() => handleClose()}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Card sx={{ maxWidth: '800px', margin: '10px', overflow: 'auto' }}>
                <CssBaseline />
                <Box
                    sx={{
                        margin: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h3" variant="h3">
                        {title}
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        sx={{ mt: 1 }}
                    >
                        <Typography component="h5" variant="h5">
                            {confirmationText}
                        </Typography>

                        <Button
                            fullWidth
                            variant="contained"
                            sx={{
                                mt: 3,
                                mb: 2,
                                backgroundColor: '#f44336',
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                    color: 'black',
                                },
                            }}
                            onClick={confirmationButtonAction}
                        >
                            {confirmationButtonText}
                        </Button>
                    </Box>
                </Box>
            </Card>
        </Modal>
    );
};
