import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button, Card, CssBaseline, TextField } from '@mui/material';
import { OrganizationUser } from '../interfaces/Interfaces';
export interface Props {
    open: boolean;
    handleClose: (instructor?: OrganizationUser | null) => void;
    instructor: OrganizationUser;
}

export const EditInstructorModal = ({
    open,
    handleClose,
    instructor,
}: Props) => {
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        // const data = new FormData(event.currentTarget);
        // const userBody = {
        //     email: instructor.email,
        //     firstName: data.get('firstName'),
        //     lastName: data.get('lastName'),
        // };

        // AuthPost('/user/roles', body, {
        //     'x-access-token': globalState.authToken,
        // })
        //     .then((res) => {
        //         if (res?.status === 200 || res?.status === 204) {
        //             toast.success('Roles Updated Successfully!');
        //             return res;
        //         }
        //         if (
        //             instructor.firstName === userBody.firstName &&
        //             instructor.lastName === userBody.lastName &&
        //         ) {
        //             handleClose(true);
        //         }
        //     })
        //     .catch((err) => {
        //         toast.error(err.toString());
        //     });
    };

    return (
        <Modal
            open={open}
            onClose={() => handleClose(null)}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Card sx={{ width: '800px', margin: '10px' }}>
                <CssBaseline />
                <Box
                    sx={{
                        margin: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5">
                        Edit Instructor
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        sx={{ mt: 1 }}
                    >
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="firstName"
                            label="First Name"
                            name="firstName"
                            autoFocus
                            defaultValue={instructor?.firstName}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="lastName"
                            label="Last Name"
                            name="lastName"
                            autoFocus
                            defaultValue={instructor?.lastName}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            autoFocus
                            defaultValue={instructor?.userEmail}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="phoneNumber"
                            label="Phone #"
                            name="phoneNumber"
                            autoFocus
                            defaultValue={instructor?.phoneNumber}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            EDIT INSTRUCTOR
                        </Button>
                    </Box>
                </Box>
            </Card>
        </Modal>
    );
};
