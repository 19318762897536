import * as React from 'react';
import Box from '@mui/material/Box';
import { Card, Grid, Typography } from '@mui/material';
import { Module, ModulePage } from '../interfaces/Interfaces';
import { formatDateWithTime2 } from '../utilities/Utilites';
import { DateTime } from 'luxon';
import Slider from 'react-slick';
import { PagePreviewForCarousel } from './PagePreviewForCarousel';
import { AuthPost } from '../utilities/Routing/Requests';
import { useGlobalStore } from '../utilities/GlobalState';
import { toast } from 'react-toastify';
import Overlay from '../utilities/Overlay/Overlay';

export interface Props {
    module: Module;
}

export const ModulePreviewDetails = ({ module }: Props) => {
    const globalState = useGlobalStore((state) => state);
    const settings = {
        dots: true,
        adaptiveHeight: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
    };

    const [pages, setPages] = React.useState<Array<ModulePage>>();

    const getModulePages = async (moduleId: number) => {
        let data: Array<any> = [];
        return AuthPost(
            '/organization/modulepages',
            { moduleId: moduleId },
            {
                'x-access-token': globalState.authToken,
                canCache: true,
            },
        )
            .then((res) => {
                if (res?.status === 200) {
                    data = res?.data;
                    setPages(
                        data?.sort(
                            (a: any, b: any) =>
                                Number(a.pageNumber) - Number(b.pageNumber),
                        ),
                    );
                    return res.data;
                } else {
                    return [];
                }
            })
            .catch((err) => {
                toast.error(err.toString());
            });
    };

    React.useEffect(() => {
        getModulePages(module.id);
    }, [module]);

    return (
        <Card
            sx={{
                // width: '800px',
                margin: '10px',
                outline: 'none',
                overflow: 'auto',
            }}
        >
            <h3 style={{ textAlign: 'center' }}>
                {module?.code + ': ' + module?.title}
            </h3>
            <Box
                sx={{
                    margin: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Grid
                    container
                    style={{
                        padding: '10px 10px 10px 10px',
                        // backgroundColor: '#f44336',
                        // color: '#fff',
                        // fontWeight: 'bold',
                        // fontSize: '20px',
                    }}
                >
                    <Grid xs={6}>
                        {module?.image?.length ? (
                            <div style={{ textAlign: 'center' }}>
                                <img
                                    style={{ width: '50%' }}
                                    src={module?.image || ''}
                                    alt="Module Details Missing Image"
                                />
                            </div>
                        ) : (
                            <></>
                        )}
                    </Grid>
                    <Grid xs={6} sx={{ paddingBottom: '20px' }}>
                        <div>Duration: {module?.duration}</div>
                        <br />
                        <div>Concepts: {module?.concepts}</div>
                        <br />
                        <div>
                            Publish Date:{' '}
                            {formatDateWithTime2(
                                DateTime.fromISO(module?.publishDate || ''),
                            )}
                        </div>
                    </Grid>
                    <Grid xs={3} sx={{ paddingBottom: '20px' }}>
                        <div style={{ textAlign: 'center' }}>Description:</div>
                    </Grid>
                    <Grid xs={9}>
                        <div>{module?.description}</div>
                    </Grid>
                    <Grid xs={3} sx={{ paddingBottom: '20px' }}>
                        <div style={{ textAlign: 'center' }}>Summary:</div>
                    </Grid>
                    <Grid xs={9}>
                        <div>{module?.summary}</div>
                    </Grid>
                    <Grid xs={3} sx={{ paddingBottom: '20px' }}>
                        <div style={{ textAlign: 'center' }}>
                            Instructor Notes:
                        </div>
                    </Grid>
                    <Grid xs={9}>
                        <div>{module?.instructorNotes}</div>
                    </Grid>
                    <Grid xs={3} sx={{ paddingBottom: '20px' }}>
                        <div style={{ textAlign: 'center' }}>
                            Certifications:
                        </div>
                    </Grid>
                    <Grid xs={9} sx={{ paddingBottom: '20px' }}>
                        <div>
                            {module?.certifications?.length
                                ? JSON.parse(module?.certifications)?.map(
                                      (cert: any, i: number) => {
                                          if (
                                              module?.certificationsDetails
                                                  ?.length
                                          ) {
                                              return (
                                                  <>
                                                      <div>
                                                          <Typography>
                                                              {i +
                                                                  1 +
                                                                  ') ' +
                                                                  cert +
                                                                  ': ' +
                                                                  JSON.parse(
                                                                      module?.certificationsDetails,
                                                                  )?.[i]}
                                                          </Typography>
                                                      </div>
                                                  </>
                                              );
                                          }
                                          return <></>;
                                      },
                                  )
                                : ''}
                        </div>
                    </Grid>
                    <Grid xs={3} sx={{ paddingBottom: '20px' }}>
                        <div style={{ textAlign: 'center' }}>Equipment:</div>
                    </Grid>
                    <Grid xs={9} sx={{ paddingBottom: '20px' }}>
                        <div>
                            {module?.equipments?.length
                                ? JSON.parse(module?.equipments)?.map(
                                      (quip: any, i: number) => {
                                          if (
                                              module?.equipmentsDetails?.length
                                          ) {
                                              return (
                                                  <>
                                                      <div>
                                                          <Typography>
                                                              {i +
                                                                  1 +
                                                                  ') ' +
                                                                  quip +
                                                                  ': ' +
                                                                  JSON.parse(
                                                                      module?.equipmentsDetails,
                                                                  )?.[i]}
                                                          </Typography>
                                                      </div>
                                                  </>
                                              );
                                          }
                                          return <></>;
                                      },
                                  )
                                : ''}
                        </div>
                    </Grid>
                    <Grid xs={3} sx={{ paddingBottom: '20px' }}>
                        <div style={{ textAlign: 'center' }}>Outcomes:</div>
                    </Grid>
                    <Grid xs={9} sx={{ paddingBottom: '20px' }}>
                        <div>
                            {module?.outcomes?.length
                                ? JSON.parse(module?.outcomes)?.map(
                                      (cert: any, i: number) => {
                                          if (module?.outcomesDetails?.length) {
                                              return (
                                                  <>
                                                      <div>
                                                          <Typography>
                                                              {i +
                                                                  1 +
                                                                  ') ' +
                                                                  cert +
                                                                  ': ' +
                                                                  JSON.parse(
                                                                      module?.outcomesDetails,
                                                                  )?.[i]}
                                                          </Typography>
                                                      </div>
                                                  </>
                                              );
                                          }
                                          return <></>;
                                      },
                                  )
                                : ''}
                        </div>
                    </Grid>
                    <Grid xs={12} sx={{ width: '700px' }}>
                        {pages?.length ? (
                            <div className="slider-container">
                                <Slider {...settings}>
                                    {pages?.map((page, i) => (
                                        <div key={'page-preview-' + page.id}>
                                            {i === 0 ? (
                                                <Overlay show={true} />
                                            ) : (
                                                <></>
                                            )}
                                            <Card>
                                                <h3
                                                    style={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    Page {page.pageNumber}{' '}
                                                    Preview
                                                </h3>
                                                <PagePreviewForCarousel
                                                    page={page}
                                                    currentPage={page.id}
                                                    classID={1}
                                                />
                                            </Card>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        ) : (
                            <></>
                        )}
                    </Grid>
                </Grid>
            </Box>
        </Card>
    );
};
