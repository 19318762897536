import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button, Card, CssBaseline, TextField } from '@mui/material';
import { AuthPost } from '../utilities/Routing/Requests';
import { toast } from 'react-toastify';
import { useGlobalStore } from '../utilities/GlobalState';
import { typeSelectOptionMapper, userTypes } from '../utilities/Utilites';
import { MultiSelectOption } from '../interfaces/Interfaces';
import { MultiSelect } from '../utilities/MultiSelect';

export interface Props {
    open: boolean;
    handleClose: (refresh?: boolean) => void;
}

export const CreateAccountModal = ({ open, handleClose }: Props) => {
    const globalState = useGlobalStore((state) => state);
    const [typesArray, setTypesArray] =
        React.useState<Array<MultiSelectOption>>();
    const selectDataHandler = (data: Array<MultiSelectOption>) => {
        setTypesArray(data);
    };
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const body = {
            UserEmail: data.get('email'),
            Password: data.get('password'),
            FirstName: data.get('firstName'),
            LastName: data.get('lastName'),
            Type: typesArray?.map((userType) => userType.key).toString(),
            OrgID: data.get('orgId'),
            active: true,
        };
        AuthPost('/user/register', body, {
            'x-access-token': globalState.authToken,
        })
            .then((res) => {
                if (res?.status === 200) {
                    toast.success('Account Successfully Created!');
                    handleClose(true);
                }
            })
            .catch((err) => {
                toast.error(err.toString());
            });
    };
    return (
        <Modal
            open={open}
            onClose={() => handleClose()}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Card sx={{ width: '800px', margin: '10px' }}>
                <CssBaseline />
                <Box
                    sx={{
                        margin: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5">
                        Create New User Account
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        sx={{ mt: 1 }}
                    >
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="firstName"
                            label="First Name"
                            name="firstName"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="lastName"
                            label="Last Name"
                            name="lastName"
                        />
                        <TextField
                            type="email"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                        />
                        <MultiSelect
                            title="Type *"
                            options={typeSelectOptionMapper(userTypes)}
                            dataHandler={selectDataHandler}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            CREATE USER ACCOUNT
                        </Button>
                    </Box>
                </Box>
            </Card>
        </Modal>
    );
};
