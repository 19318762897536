import React from 'react';

const Overlay = ({ show }: any) => {
    if (!show) {
        return null;
    }

    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.05)', // Semi-transparent background
                zIndex: 1000, // Ensure it's on top
            }}
        />
    );
};

export default Overlay;
